import ApplicationController from '../../javascript/controllers/application_controller'

import { useClickOutside, useDebounce } from 'stimulus-use'

export default class extends ApplicationController {
  static targets = ['result', 'form', 'search', 'close', 'input']
  static debounces = ['search']

  initialize() {
    useDebounce(this, { wait: 600 })
    useClickOutside(this)
  }

  openSearch() {
    this.searchTarget.classList.remove('soft-hidden')
    this.closeTarget.classList.remove('soft-hidden')
    this.inputTarget.focus()

    this.element.classList.remove('md:w-48', 'md:w-72', 'relative')
    this.element.classList.add('w-full', 'absolute')
  }

  closeSearch() {
    this.searchTarget.classList.add('soft-hidden')
    this.closeTarget.classList.add('soft-hidden')

    this.element.classList.remove('w-full', 'absolute')
    this.element.classList.add('relative')
  }

  clickOutside() {
    this.element.classList.add('md:w-48')
    this.element.classList.remove('md:w-72')
    this.resultTarget.classList.add('hidden')
    this.closeSearch()
  }

  focus() {
    this.element.classList.remove('md:w-48')
    this.element.classList.add('md:w-72')

    if (this.resultTarget.dataset.resultSize > 0) {
      this.resultTarget.classList.remove('hidden')
    }
  }

  search() {
    this.formTarget.requestSubmit()
  }
}
