import React from 'preact/compat'
import { createContext, h } from 'preact'
import { useContext, useCallback } from 'preact/hooks'
import { signal } from '@preact/signals'
import { SignUp } from './landing_pages/sign_up'
import { Merchandise } from './landing_pages/merchandise'
import { Confirmation } from './landing_pages/confirmation'
import { StepTracker } from './landing_pages/step_tracker'
import { Cart } from './landing_pages/cart'

const LandingPageState = createContext({
  step: signal(1),
  numberOfSteps: 3,
  members: signal([{ primary: true }]),
  leadCaptureForm: {},
  guardianLeadCaptureForm: {},
  merchandise: null,
  cart: signal(new Map()),
  errors: signal({}),
  familyCreation: false,
})

export function useLeadCaptureForm() {
  const { leadCaptureForm, guardianLeadCaptureForm } = useContext(LandingPageState)

  return { leadCaptureForm, guardianLeadCaptureForm }
}

export function useFamilyCreation() {
  const { familyCreation } = useContext(LandingPageState)

  return familyCreation
}

export function useMerchandise() {
  const { merchandise } = useContext(LandingPageState)

  return merchandise
}

export function useCart() {
  const { cart } = useContext(LandingPageState)

  const addToCart = useCallback((item) => {
    const _cart = new Map(cart.value)

    if (_cart.has(item)) {
      const current = _cart.get(item)

      const max = item.remaining || 99

      _cart.set(item, Math.min(current + 1, max))
    } else {
      _cart.set(item, 1)
    }

    cart.value = _cart
  })

  const removeFromCart = useCallback((item) => {
    const _cart = new Map(cart.value)

    if (_cart.get(item) > 1) {
      const current = _cart.get(item)

      _cart.set(item, current - 1)
    } else {
      _cart.delete(item)
    }

    cart.value = _cart
  })

  const setQuantity = useCallback((item, quantity) => {
    const _cart = new Map(cart.value)

    quantity ||= 0

    const max = item.remaining || 99

    _cart.set(item, Math.min(Math.max(quantity, 0), max))

    cart.value = _cart
  })

  return { cart: cart.value, addToCart, removeFromCart, setQuantity }
}

export function useStep() {
  const { step, numberOfSteps } = useContext(LandingPageState)

  const nextStep = () => {
    if (step.value < numberOfSteps) {
      step.value++
    }
  }

  const jumpToStep = (newStep) => {
    step.value = newStep
  }

  const previousStep = () => {
    if (step.value > 1) {
      step.value--
    }
  }

  return { step: step.value, numberOfSteps, nextStep, previousStep, jumpToStep }
}

export function useMembers() {
  const { members } = useContext(LandingPageState)

  const addMember = () => {
    members.value = [...members.value, { participating: true, usePrimaryInfo: true }]
  }

  const ensureParticipant = () => {
    if (members.value.filter((member) => member.participating).length < 1) {
      addMember()
    }
  }

  return { members: members.value, addMember, ensureParticipant }
}

export function useError() {
  const { errors } = useContext(LandingPageState)

  const setError = (value) => {
    errors.value = value
  }

  return { errors: errors.value, setError }
}

function LandingPageCore() {
  const { step, numberOfSteps } = useStep()

  return (
    <div class="mt-6">
      <div class="flex item-center gap-4 justify-between mb-4">
        <div class="text-xl font-bold">Sign Up</div>

        <div className="flex items-center gap-2">
          <StepTracker />

          <Cart />
        </div>
      </div>

      {step === 1 && <SignUp />}
      {step === 2 && step < numberOfSteps && <Merchandise />}
      {step === numberOfSteps && <Confirmation />}
    </div>
  )
}

export function LandingPage({ leadCaptureForm, guardianLeadCaptureForm, merchandise = true }) {
  let numberOfSteps = 2

  if (merchandise) numberOfSteps = 3

  return (
    <LandingPageState.Provider
      value={{
        step: signal(1),
        numberOfSteps,
        members: signal([{ participating: true }]),
        errors: signal({}),
        leadCaptureForm,
        guardianLeadCaptureForm,
        merchandise,
        cart: signal(new Map()),
        familyCreation: false,
      }}
    >
      <LandingPageCore />
    </LandingPageState.Provider>
  )
}
