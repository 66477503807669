import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'newContact',
    'newStudent',
    'existingPerson',
    'newPersonForm',
    'existingPersonForm',
    'existingPersonField',
    'exceedStudentLimit',
    'submit',
  ]

  static values = { newStudentDisable: Boolean }

  toggleForm() {
    if (this.newStudentTarget.checked || this.newContactTarget.checked) {
      this.existingPersonFieldTarget.tomselect.disable()
      if (this.newStudentTarget.checked && this.newStudentDisableValue) {
        this.existingPersonFormTarget.classList.add('hidden')
        this.exceedStudentLimitTarget.classList.remove('hidden')
        this.newPersonFormTarget.classList.add('hidden')
        this.submitTarget.classList.add('hidden')
      } else {
        this.newPersonFormTarget.classList.remove('hidden')
        this.existingPersonFormTarget.classList.add('hidden')
        this.exceedStudentLimitTarget.classList.add('hidden')
        this.submitTarget.classList.remove('hidden')
      }
    } else if (this.existingPersonTarget.checked) {
      this.existingPersonFieldTarget.tomselect.enable()
      this.newPersonFormTarget.classList.add('hidden')
      this.existingPersonFormTarget.classList.remove('hidden')
      this.exceedStudentLimitTarget.classList.add('hidden')
      this.submitTarget.classList.remove('hidden')
    }
  }
}
